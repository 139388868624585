#confettis {
    overflow: hidden;
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .confetti {
    left: 50%;
    width: 16px;
    height: 16px;
    position: absolute;
    transform-origin: left top;
    animation: confetti 7s ease-in-out -2s infinite;
  }
  @keyframes confetti {
    0% {
      transform: rotateZ(15deg) rotateY(0deg) translate(0, 0);
    }
    25% {
      transform: rotateZ(5deg) rotateY(360deg) translate(-5vw, 20vh);
    }
    50% {
      transform: rotateZ(15deg) rotateY(720deg) translate(5vw, 60vh);
    }
    75% {
      transform: rotateZ(5deg) rotateY(1080deg) translate(-10vw, 80vh);
    }
    100% {
      transform: rotateZ(15deg) rotateY(1440deg) translate(10vw, 110vh);
    }
  }

  .confetti:nth-of-type(1) {
    left: 10%;
    animation-delay: 0;
    background-color: #fc0120;
  }
  .confetti:nth-of-type(2) {
    left: 20%;
    animation-delay: -5s;
    background-color: #8257e6;
  }
  .confetti:nth-of-type(3) {
    left: 30%;
    animation-delay: -3s;
    background-color: #ffbf4d;
  }
  .confetti:nth-of-type(4) {
    left: 40%;
    animation-delay: -2.5s;
    background-color: #fe5d7a;
  }
  .confetti:nth-of-type(5) {
    left: 50%;
    animation-delay: -4s;
    background-color: #45ec9c;
  }
  .confetti:nth-of-type(6) {
    left: 60%;
    animation-delay: -6s;
    background-color: #f6e327;
  }
  .confetti:nth-of-type(7) {
    left: 70%;
    animation-delay: -1.5s;
    background-color: #f769ce;
  }
  .confetti:nth-of-type(8) {
    left: 80%;
    animation-delay: -2s;
    background-color: #007de7;
  }
  .confetti:nth-of-type(9) {
    left: 90%;
    animation-delay: -3.5s;
    background-color: #63b4fc;
  }
  .confetti:nth-of-type(10) {
    left: 100%;
    animation-delay: -2.5s;
    background-color: #f9c4ea;
  }
  .confetti:nth-of-type(11) {
    left: 5%;
    animation-delay: -3.5s;
    background-color: #fc0120;
  }
  .confetti:nth-of-type(12) {
    left: 15%;
    animation-delay: -2s;
    background-color: #8257e6;
  }
  .confetti:nth-of-type(13) {
    left: 25%;
    animation-delay: -1.5s;
    background-color: #ffbf4d;
  }
  .confetti:nth-of-type(14) {
    left: 35%;
    animation-delay: -6s;
    background-color: #fe5d7a;
  }
  .confetti:nth-of-type(15) {
    left: 45%;
    animation-delay: -5s;
    background-color: #45ec9c;
  }
  .confetti:nth-of-type(16) {
    left: 55%;
    animation-delay: -2.5s;
    background-color: #f6e327;
  }
  .confetti:nth-of-type(17) {
    left: 65%;
    animation-delay: -3s;
    background-color: #f769ce;
  }
  .confetti:nth-of-type(18) {
    left: 75%;
    animation-delay: -5s;
    background-color: #007de7;
  }
  .confetti:nth-of-type(19) {
    left: 85%;
    animation-delay: -4s;
    background-color: #63b4fc;
  }
  .confetti:nth-of-type(20) {
    left: 95%;
    animation-delay: 0;
    background-color: #f9c4ea;
  }