.rdw-option-wrapper, .rdw-dropdown-wrapper, .rdw-image-modal-size-input {
    border: none;
    background: rgba(203, 225, 255, 0.17);
    border-radius: 60px;
}

.rdw-option-wrapper:hover, .rdw-option-wrapper:active, .rdw-dropdown-wrapper:hover, .rdw-dropdown-optionwrapper:hover {
    box-shadow: none;
}

.rdw-option-wrapper:hover {
    background: rgba(185, 215, 255, 0.08);
}

.rdw-option-active {
    background: rgba(24, 132, 255, 1);
    box-shadow: none;
}

.rdw-option-active:hover {
    background: rgba(26, 117, 222, 1);
}

.rdw-dropdown-wrapper:hover {
    background: rgba(203, 225, 255, 0.17);
}

.rdw-dropdown-optionwrapper, .rdw-dropdown-optionwrapper:hover, .rdw-colorpicker-modal, .rdw-image-modal, .rdw-link-modal {
    background: #212633;
    border: 1px solid rgba(241, 247, 255, 0.12);
    overflow: auto;
}

.rdw-dropdownoption-active,  .rdw-dropdownoption-highlighted {
    background: rgba(185, 215, 255, 0.08)
}

.rdw-dropdown-carettoopen {
    border-top: 6px solid white;
}

.rdw-dropdown-carettoclose {
    border-bottom: 6px solid white;
}

.rdw-colorpicker-modal, .rdw-image-modal, .rdw-link-modal {
    box-shadow: none;
}

.rdw-colorpicker-modal-options {
    overflow: auto;
}

.rdw-colorpicker-modal-style-label-active, .rdw-image-modal-header-label-highlighted {
    border-bottom: 1px solid white;
}

.rdw-image-modal-upload-option {
    border-radius: 10px;
    background: rgba(203, 225, 255, 0.17)
}

.rdw-image-modal-size-input, .rdw-link-modal-input {
    border: none;
    background: rgba(203, 225, 255, 0.17);
    border-radius: initial;
    color: white;
    padding: 4px;
}

.rdw-link-modal, .rdw-colorpicker-modal {
    left: 50%;
    transform: translateX(-50%);
}

.rdw-link-modal-btn {
    border-radius: 60px;
    border: none;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.rdw-link-modal-btn:hover {
    box-shadow: none;
}

.rdw-link-modal-btn:nth-child(1) {
    color: #fff;
    background-color: #1884FF;
}

.rdw-link-modal-btn:nth-child(1):not(:disabled):hover {
    background-color: rgba(26, 117, 222, 1);
}

.rdw-link-modal-btn:nth-child(2) {
    background-color: rgba(51, 146, 255, 0.25);
    color: rgba(24, 132, 255, 1);
}

.rdw-link-modal-btn:nth-child(2):not(:disabled):hover {
    background-color: rgba(51, 146, 255, 0.4);
}

.rdw-link-modal-btn:disabled {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.12);
}

.rdw-editor-main pre {
    background: rgba(185, 215, 255, 0.1);
}

.rdw-editor-main a {
    text-decoration: none;
}

.rdw-editor-main a {
    color: #1884FF;
}

.rdw-editor-main a:hover {
    text-decoration: underline;
}

.rdw-editor-main input[type='checkbox']:hover {
    cursor: pointer;
}

.rdw-colorpicker-option:last-child {
    box-shadow: none;
}

.rdw-colorpicker-option:last-child > .rdw-colorpicker-cube {
    background-image: url('../../../assets/icons/textEditor/close.svg');
    background-position: -1px;
}

.rdw-option-active:last-child {
    background: none;
}
