@keyframes rowLoadingEffect {
    from {
        background-position: 200% 0;
    }
    to {
        background-position: 0 0;
    }
}

.MuiDataGrid-root .row-action {
    background: linear-gradient(
            90deg,
            rgba(68, 67, 67, 0.13) 25%,
            rgba(100, 149, 237, 0.6) 50%,
            rgba(68, 67, 67, 0.14) 75%
    );
    background-size: 200% 100%;
    animation: rowLoadingEffect 1s linear infinite;
    opacity: 0.8;
    pointer-events: none;
    transition: opacity 0.8s ease, background 0.3s ease;
}