.linear-wrapper{
    width: 100%;
    height: 8px;
    background-color: #212633;
    display: flex;
    gap: 4px;
}

.linear-segment{
    height: 100%;
    border-radius: 10px;
}

@keyframes fillAnimation {
    from {
        width: 0%;
    }
    to {
        width: 100%;
    }
}

.linear-segment-full {
    animation: fillAnimation 0.5s ease-out forwards;
}